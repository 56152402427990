.ant-input-number-handler-wrap {
  opacity: 0 !important;
}

@media print {
  @page {
    size: A4;
    margin: 0;
  }
  body{
    margin-top: 10px;
    padding:10px;
  }
}